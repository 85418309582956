import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  MemberShipAssessmentsDto,
  CreateOrUpdateMemberShipAssessmentsDto,
  GetListMemberShipAssessmentsDto
} from '@/framework/models/MemberShipAssessments'
import {Guid} from 'guid-typescript'

export default class MemberShipAssessmentsApi extends CrudAppService <MemberShipAssessmentsDto,
  MemberShipAssessmentsDto, Guid, GetListMemberShipAssessmentsDto, CreateOrUpdateMemberShipAssessmentsDto,
  CreateOrUpdateMemberShipAssessmentsDto> {
  constructor() {
    super('/keep-fit/memberShipAssessments');
  }
}
