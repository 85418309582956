import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface MemberShipAssessmentsDto extends ExtensibleEntityDto <Guid> {
  sports: number
  heart: number
  diabetes: number
  joint: number
  pregnancy: number
  chronic: number
  plan: number
  evaluationConclusion: number
}

export class CreateOrUpdateMemberShipAssessmentsDto {
  public sports: number | undefined
  public heart: number | undefined
  public diabetes: number | undefined
  public joint: number | undefined
  public pregnancy: number | undefined
  public chronic: number | undefined
  public plan: number | undefined
  public evaluationConclusion: number | undefined
}

export class GetListMemberShipAssessmentsDto extends PagedAndSortedResultRequestDto {
  public isFinish: boolean = false
}
