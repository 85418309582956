import { render, staticRenderFns } from "./SelfAssessment.vue?vue&type=template&id=612b614b&scoped=true&"
import script from "./SelfAssessment.vue?vue&type=script&lang=ts&"
export * from "./SelfAssessment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612b614b",
  null
  
)

export default component.exports